<template>
  <div>
    <HeaderPanel
      title="Stock"
      :hasDropdown="false"
      :hasFilter="false"
      placeholder=""
      :hasSearch="false"
      hideFilter
      routerPath="/setting/inventory/stock/detail/0"
    >
    </HeaderPanel>

    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(name)="{ item }">
              <router-link :to="'/setting/inventory/stock/detail/' + item.id">
                <div class="d-flex align-items-center justify-content-center">
                  <div class="underline">{{ item.name }}</div>
                  <div>
                    <font-awesome-icon
                      v-if="item.is_main"
                      icon="check"
                      class="text-success ml-2"
                    />
                  </div></div
              ></router-link>
            </template>
            <template v-slot:cell(created_time)="{ item }">
              <div>{{ item.created_time | moment($formatDateNew) }}</div>
              <div class="text-secondary">
                {{ item.created_time | moment("(HH:mm:ss)") }}
              </div>
            </template>
            <template v-slot:cell(is_allow_to_sale)="{ item }">
              <div
                :class="
                  item.is_allow_to_sale == 1 ? 'text-success' : 'text-error'
                "
              >
                {{ item.is_allow_to_sale == "1" ? "Yes" : "No" }}
              </div>
            </template>
            <template v-slot:cell(active)="{ item }">
              <div :class="item.active == 1 ? 'text-success' : 'text-error'">
                {{ item.active == "1" ? "Active" : "InActive" }}
              </div>
            </template>
            <template v-slot:cell(action)="{ item }">
              <div class="d-flex justify-content-center">
                <router-link :to="'/setting/inventory/stock/detail/' + item.id">
                  <b-button variant="link" class="text-warning px-1 py-0">
                    <font-awesome-icon icon="pencil-alt" title="Edit" />
                  </b-button>
                </router-link>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <Pagination
        @handleChangeTake="handleChangeTake"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </div>
    <FooterAction routePath="/setting/inventory" :hideSubmit="true" />
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      filter: {
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Description",
          key: "description",
          thClass: "w-5",
        },
        {
          label: "Create Date",
          key: "created_time",
        },
        {
          label: "Sort Order",
          key: "sort_order",
        },

        {
          label: "Status",
          key: "active",
        },
        {
          label: "Allow To Sell",
          key: "is_allow_to_sale",
        },
        {
          label: "Action",
          key: "action",
        },
      ],
      items: [],
      data: {
        total: 0,
        total_success: 0,
        total_pending: 0,
      },
    };
  },
  created() {
    this.getList();
  },

  methods: {
    async getList() {
      let payload = { ...this.filter };

      this.isBusy = true;
      const res = await this.axios.post(`/setting/stock/list`, payload);

      this.rows = res.data.detail.count;
      this.items = res.data.detail.data;
      this.isBusy = false;
    },

    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
  },
};
</script>
<style lang="scss">
.banner-image {
  max-width: 50px;
  height: auto;
  width: 100%;
  aspect-ratio: 1;
}
</style>
